import React from "react";
import styled from "styled-components";
import { Typography } from "@material-ui/core";
import ContentfulImage from "../ContentfulImage";
import ContentWidthWrapper from "../ContentWidthWrapper";

const ContactStage = ({ title, image }: { title: string; image: string }) => {
  return (
    <Root>
      <ContentWidthWrapper>
        <Content>
          <ContentfulImage url={image} />
          <Headline variant="h1" color="textSecondary">
            {title}
          </Headline>
        </Content>
      </ContentWidthWrapper>
    </Root>
  );
};
export default ContactStage;

const Root = styled.div(
  ({ theme }) => `
  padding: ${theme.spacing(10, 0, 0)};
  ${theme.breakpoints.up("sm")} {
  padding: ${theme.spacing(15, 0, 0)};
  }

  `
);

const Content = styled.div`
  position: relative;
`;

const Headline = styled(Typography)(
  ({ theme }) => `
    position: absolute;
    bottom: ${theme.spacing(3)}px;
    left: ${theme.spacing(3)}px;
    ${theme.breakpoints.up("sm")} {
        bottom: ${theme.spacing(6)}px;
        left: ${theme.spacing(3)}px;
    }
    ${theme.breakpoints.up("md")} {
        bottom: ${theme.spacing(7)}px;
        left: ${theme.spacing(6)}px;
    }
    ${theme.breakpoints.up("lg")} {
      bottom: ${theme.spacing(12)}px;
    }
`
);
