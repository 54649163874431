import React from "react";
import Layout from "src/common/Layout";
import { graphql } from "gatsby";
import { ContactPageQuery } from "types/graphql-types";
import "react-json-pretty/themes/1337.css";
import ContactStage from "src/common/components/Stages/Contact";
import ContactArea from "src/common/contact/ContactArea";

import styled from "styled-components";
import { Grid } from "@material-ui/core";
import SEO from "src/common/components/seo";
import MapContainer from "src/common/contact/Map";
import ContentWidthWrapper from "src/common/components/ContentWidthWrapper";

const career = ({
  data: { contentfulContactPage, contentfulFooter, contentfulHeader },
}: {
  data: ContactPageQuery;
}) => {
  if (!contentfulContactPage || !contentfulFooter || !contentfulHeader) {
    throw new Error(
      "Not all Contentful requirements met: contentfulContactPage, contentfulFooter, contentfulHeader"
    );
  }
  const { pageConfig, contact } = contentfulContactPage;
  return (
    <Layout
      contentfulFooter={contentfulFooter}
      contentfulHeader={contentfulHeader}
      stageContent={
        <ContactStage
          title={pageConfig!.title!.title!}
          image={pageConfig!.headerImage?.file?.url!}
        />
      }
    >
      <SEO
        title={pageConfig!.seoConfig?.title!}
        description={pageConfig!.seoConfig?.description}
        image={pageConfig!.seoConfig?.image?.localFile?.publicURL!}
      />
      <WidthWrapper>
        <ContactArea contact={contact} absolute={true} />
      </WidthWrapper>

      <MapWrapper>
        <ContentWidthWrapper>
          <MapContainer />
        </ContentWidthWrapper>
      </MapWrapper>
    </Layout>
  );
};

export const query = graphql`
  query ContactPage  ($locale:String!){
    contentfulContactPage (node_locale: {eq: $locale}){
      pageConfig {
        title {
          title
        }
        headerImage {
          file {
            url
          }
          localFile {
            publicURL
          }
        }
        seoConfig {
          title
          description
          image {
            localFile {
              publicURL
            }
            file {
              url
            }
          }
        }
      }

      mapLatitude
      mapLongitude
      contact {
        faxLabel
        mailAdress
        subtitleFirstLine
        subtitleSecondLine
        telephoneLabel
        telephoneNumber
        title
        website
      }
    }
    contentfulFooter (node_locale: {eq: $locale}){
      title
      text
      copyright
      columnOneTitle
      columnOneLinks {
        id
        label
        url
        type
      }
      columnTwoTitle
      columnTwoLinks {
        id
        label
        url
        type
      }
      columnThreeTitle
      columnThreeLinks {
        id
        label
        url
        type
      }
      instagramLink
      facebookLink
      pinterestLink
      columnFourTitle
      childContentfulFooterLanguagesListJsonNode {
        de
        en
      }
      chosenLang
      deGermanFlag {
        file {
          url
        }
      }
      enEnglishFlag {
        file {
          url
        }
      }
      chosenFlag {
        file {
          url
          fileName
        }
      }
    }
    contentfulHeader (node_locale: {eq: $locale}){
      links {
        id
        label
        url
        type
      }
    }
  }
`;

export default career;

const WidthWrapper = styled(Grid)`
  position: relative;
  max-width: 1280px;
  margin: 0 auto;
`;
const MapWrapper = styled.div(
  ({ theme }) => `

  margin-bottom: ${theme.spacing(5)}px;
  ${theme.breakpoints.up("sm")} {
    margin-top: ${theme.spacing(23)}px;
  margin-bottom: ${theme.spacing(5)}px;
  }
  ${theme.breakpoints.up("md")} {
    margin-top: -80px;
    margin-bottom: ${theme.spacing(10)}px;
  }
`
);
