import React, { useEffect, useState, useCallback } from "react";

import { StaticGoogleMap, Marker } from "react-static-google-map";
import styled from "styled-components";
import { useMediaQuery } from "@material-ui/core";
import theme from "src/constants/theme";

const API_KEY = process.env.GATSBY_GOOGLE_STATIC_MAP_TOKEN;

const MapContainer = () => {
  const isDesktop = useMediaQuery(theme.breakpoints.up("md"));

  const isBrowser = typeof window !== "undefined";
  const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);

  const handleResize = useCallback(() => {
    if (isDesktop) {
      setWidth(1280);
    } else {
      setWidth(600);
    }
  }, [isDesktop]);

  useEffect(() => {
    if (!isBrowser) return;
    window.addEventListener("resize", handleResize);
    return window.removeEventListener("resize", handleResize);
  }, [handleResize, isBrowser]);

  const mapWidth = width / 2;
  const zoomFactor = mapWidth < 600 ? 13 : 14;

  return (
    <Container id="MapContainer">
      <GoogleMap
        size={`${1280}x${300}`}
        apiKey={API_KEY}
        center="49.9166348,9.135888"
        zoom={zoomFactor}
        scale="4"
        // eslint-disable-next-line react/style-prop-object
        style={"feature:poi|visibility:off|feature:road.arterial|visibility:on"}
      >
        <Marker location="49.9174841,9.1296936" color="red" size="small" />
      </GoogleMap>
    </Container>
  );
};

export default MapContainer;

const Container = styled.div``;
const GoogleMap = styled(StaticGoogleMap)`
  width: 100%;
  height: auto;
`;
